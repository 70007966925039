'use strict'
angular
	.module('cb.account-controller', [])
	.controller('AccountController', ["$scope", "$rootScope", "AccountService", "NotificationService", function ($scope, $rootScope, AccountService, NotificationService) {

		$scope.$watch('activityPageNumber', function (n, o) {
			getActivityLog();
		});

		$scope.init = function () {
			$scope.activityPageNumber = 1;
			$scope.activityPagination = {};
			getUserDetails(true);
		};

		function getUserDetails(init) {
			$scope.GettingAccountPromise = AccountService.getAllUsers();
			$scope.GettingAccountPromise.then(function (output) {
				$scope.user = output.data.StaffList.find(member => member.Id === $rootScope.User.Id);
				$scope.userCopy = angular.copy($scope.user);

				if (init)
					getActivityLog();
			}, function () {
				NotificationService.alert('User Details Error', 'There was an error retrieving your details.', 'error', {});
			});
		};

		function getActivityLog() {
			if ($scope.user) {
				var data = {
					UserId: $scope.user.Id,
					StartDate: moment().startOf('hour').add(-1, 'year'),
					EndDate: moment().startOf('hour'),
					Filters: [],
					Pagination: {
						StartPage: $scope.activityPageNumber - 1,
						PageSize: 10
					}
				};
				$scope.GettingActivityPromise = AccountService.getUserActivity(data);
				$scope.GettingActivityPromise.then(function (output) {
					$scope.activityLog = output.data.Logs;
					$scope.activityPagination = output.data.PaginationStatus;
				}, function () {
					NotificationService.alert('User Activity Error', 'There was an error retrieving your activity.', 'error', {});
				});
			};
		};

		$scope.updateUser = function () {
			$scope.userCopy.Department = $scope.userCopy.Department.Id;
			$scope.userCopy.Roles = $scope.userCopy.Roles.map(role => role.Id);
			$scope.UpdatingUserPromise = AccountService.updateUser($scope.userCopy);
			$scope.UpdatingUserPromise.then(function () {
				NotificationService.alert('Details Updated', 'Your details have been successfully updated.', 'success', {});
				getUserDetails(false);
			}, function () {
				$scope.userCopy = angular.copy($scope.user);
				NotificationService.alert('Details Error', 'There was an error updating your details.', 'error', {});
			});
		};

	}])
